import React from 'react';
import { Link } from 'react-router-dom';

export default function Nav() {
  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className='header'>
      <ul className='navLinks'>
        <li>
          <Link to='/' onClick={handleClick}>
            Home
          </Link>
        </li>
        <li>
          <Link to='/portfolio' onClick={handleClick}>
            Portfolio
          </Link>
        </li>
        <li>
          <Link to='/contact' onClick={handleClick}>
            Contact Me
          </Link>
        </li>
        <li>
          <a href='./Snarr_Resume.pdf' target="_blank" rel="noopener noreferrer">
            Resume
          </a>
        </li>
      </ul>
    </div>
  );
}
