import React from 'react';
import Foot from '../components/Foot';
import Projects from '../components/Projects';
import { projectData } from '../data/projectData';
import Nav from '../components/Nav';

export default function Portfolio() {
    return (
        <>
            <div className='prlx2' id='projects'>
                <div className='caption'>
                    <span className='projects' >Portfolio</span>
                </div>
            </div>
            <Nav />
            {projectData.map((project) => (
                <><Projects
                    name={project.name}
                    languages={project.languages}
                    link={project.link}
                    img={project.img}
                    desc={project.desc}
                    deployment={project.deployment}
                /><hr /></>
            ))}
            <Foot />
        </>
    );
};
