import React from 'react';
import Foot from '../components/Foot';
import Bio from '../components/Bio'
import Nav from '../components/Nav';

export default function Home() {
    return (
        <>

            <div className='prlx1'>
                <div className='caption'>
                    <span className='name'>Amy Snarr</span>
                </div>
            </div>
            <Nav />
            <Bio />
            <Foot />
        </>
    );
};
