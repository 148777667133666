import React from 'react';
import './styles/App.css';
import Home from './pages/Home';
import Portfolio from './pages/Portfolio';
import ContactMe from './pages/ContactMe'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';



export default function App() {
  return (
    <Router>

      <Routes>

        <Route
          path='/'
          element={<Home />}
        />

        <Route
          path='/portfolio'
          element={<>

            <Portfolio />
          </>}
        />
        <Route
          path='/contact'
          element={<>

            <ContactMe />
          </>}
        />
      </Routes>
    </Router>
  );
}
