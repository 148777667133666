import React from 'react';

export default function Projects(props) {
    return (
        <div className='content projectContainer '>
            <div className='imgContainer'>
                <img src={props.img} alt={props.name} title={props.name} className='projectImg'/>
            </div>
            <div className='item'>
                <a href={props.link} target="_blank" rel="noreferrer">{props.name}
                    <br />{props.languages}</a>
                <br />{props.desc}
                <br />
                {props.deployment ? (
                    <a href={props.deployment} target="_blank" rel='noreferrer'>Click to view deployment</a>
                ) : null}
            </div>
        </div>
    )
}
