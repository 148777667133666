import React from 'react';
import Foot from '../components/Foot';
import Contact from '../components/Contact';
import Nav from '../components/Nav';
import Skills from '../components/Skills';

export default function ContactMe() {
    return (
        <>
            <div className='prlx3' id='contact'>
                <div className='caption'>
                    <span className='contact'>Contact Me</span>
                </div>
            </div>
            <Nav />
            <Contact />
            <Skills />
            <Foot />
        </>
    );
};
