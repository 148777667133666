import React from 'react';
import bike from '../assets/bike.png';
import dogs from '../assets/dogs.png';
import grad from '../assets/graduation.png';
import profile from '../assets/profile_pic.PNG';

export default function Bio() {

    return (
        <div className='content' id='about-me'>
            <div className='flex mainBio'>
                <p className='pic1'><img src={profile} title='Profile photo' alt='profile' className='pic' /></p>
                <p className='desc'>
                    Hello! My given name is Amparo, but you can call me Amy.
                    I am half-Mexican, I grew up in Puerto Vallarta, Jalisco and I miss it so much!
                    I have a passion for math, coding, and all things logic. I love to crochet and cross stitch, and solve puzzles with my two daughters.
                    I have a B.S. in Math, and decided to join the University of Utah Full Stack Coding Bootcamp in October of 2022.
                    I have since graduated, and am ready for anything that comes my way!
                </p>
            </div><br />

            <div>
                <p className='favorites'>

                    Here are some favorites of mine:
                    <br />Flower: Peony
                    <br />Meal: Pozole
                    <br />Fruit: Mango
                    <br />Fast food: Popeye's
                    <br />Theme park: Busch Gardens
                    <br />Musician: Taylor Swift
                    <br />Animal: Owl
                </p>  <br />
                <div className='flex graduation'>

                    <p className='desc'>
                        I met my husband in 2015, got married a year later, and moved to Williamsburg, VA which is my second favorite place in the world.
                        We welcomed our first daughter the day after my husband's law school graduation!
                        I actually realized I was in labor right as he was walking across the stage!
                    </p>
                    <p className='pic1'><img src={grad} title='Graduation photo' alt='graduation' className='pic' /></p>
                </div><br />
                <div className='flex dogs'>
                    <p className='pic1'><img src={dogs} title='Dogs photo' alt='dogs' className='pic' /></p>
                    <p className='desc'>
                        I have two beautiful daughters that keep me very entertained and so busy on the weekends.
                        I also have two very different dogs that have a love-hate relationship.
                        Gus (the Goldendoodle) cannot let Molly (the Shih-Tzu) have one clean day after getting a bath, he likes to play with her by pretending to eat her!!
                    </p>
                </div><br />
                <div className='flex bike'>
                    <p className='desc'>
                        When I do have free time for myself, I enjoy working on one of my many crochet work-in-progress projects.
                        I also like to cook, go on walks with my family (when it's above 30 degrees), and go on bike rides in the summer.
                    </p>
                    <p className='pic1'><img src={bike} title='Bike photo' alt='bike' className='pic' /></p>
                </div>
            </div>
        </div>
    );
}
