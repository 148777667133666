import React from 'react';
import { AiOutlineMessage, AiOutlineMail, AiOutlineGithub, AiOutlineLinkedin } from 'react-icons/ai';

export default function Contact() {
    return (
        <div className='content'>
            <p>
                Please feel free to reach out in any of the following ways.
            </p>
            <ul className='iconList'>
                <li><a href="sms:18015203022"><AiOutlineMessage /></a></li>
                <li><a href="mailto:amy.snarr16@gmail.com"><AiOutlineMail /></a></li>
                <li><a href="https://github.com/sifrult"><AiOutlineGithub /></a></li>
                <li><a href="https://www.linkedin.com/in/amysnarr/"><AiOutlineLinkedin /></a></li>
            </ul>
        </div>
    );
}
