import React from "react";

export default function Skills() {
    return (
        <div className='content'>
            <p>
                Look what I can do!
            </p>
            <ul className="skillList">
                <li><img src="https://skillicons.dev/icons?i=html" title="HTML" alt="HTML" /></li>
                <li><img src="https://skillicons.dev/icons?i=css" title="CSS" alt="CSS" /></li>
                <li><img src="https://skillicons.dev/icons?i=bootstrap" title="Bootstrap" alt="Bootstrap" /></li>
                <li><img src="https://skillicons.dev/icons?i=js" title="JavaScript" alt="JavaScript" /></li>
                <li><img src="https://skillicons.dev/icons?i=jquery" title="JQuery" alt="JQuery" /></li>
                <li><img src="https://skillicons.dev/icons?i=mysql" title="MySQL" alt="MySQL" /></li>
                <li><img src="https://skillicons.dev/icons?i=mongodb" title="MongoDB" alt="MongoDB" /></li>
                <li><img src="https://skillicons.dev/icons?i=nodejs" title="NodeJS" alt="NodeJS" /></li>
                <li><img src="https://skillicons.dev/icons?i=react" title="React" alt="React" /></li>
                <li><img src="https://skillicons.dev/icons?i=vscode" title="VSCode" alt="VSCode" /></li>
            </ul>
        </div>
    )
}
