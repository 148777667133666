import gourmet from '../assets/projects/Screenshot-gourmet.png'
import wizard from '../assets/projects/Screenshot-wizard.png';
import streaming from '../assets/projects/Screenshot-streaming-locator.png';
import team from '../assets/projects/Screenshot-team-generator.png';
import social from '../assets/projects/Screenshot-social-network-api.png';

export const projectData = [
    {
        name: "Streaming Locator",
        link: 'https://github.com/sifrult/streaming_locator',
        languages: "HTML, CSS, JS",
        img: streaming,
        desc: 'This application allows users to identify the streaming service for any tv show or movie they want to watch. Users also have access to trailers, reviews, and cast.',
        deployment: 'https://sifrult.github.io/streaming_locator/'
    },
    {
        name: "Ollisander's",
        link: 'https://github.com/Ksteed8859/wizard-wands',
        languages: 'Mongo, Express, React, Node',
        img: wizard,
        desc: 'A wand store where a user can personalize their wand by style, color, length, core, and flexibility.',
        deployment: 'https://wizard-wands-production-156b.up.railway.app'
    },
    {
        name: 'Team Profile Generator',
        link: 'https://github.com/sifrult/team_profile_generator',
        languages: 'Node, HTML, CSS, JS',
        img: team,
        desc: "Users will use Node to create their team with each one's name, id, email, and office number. When complete, an HTML and CSS template will be created."
    },
    {
        name: 'Gourmet',
        link: 'https://github.com/brenda255/gourmet',
        languages: 'HTML, CSS, JS, Node, Handlebars, MySQL, Sequelize',
        img: gourmet,
        desc: 'This application allows users to create an online recipe book when they sign up for an account. Users can choose from a pre-populated list of recipes, or add their own.',
        deployment: 'https://gourmet-9213649a68cc.herokuapp.com/'
    },
    {
        name: 'Social Network API',
        link: 'https://github.com/sifrult/social-network-api',
        languages: 'Mongo, Node, JS',
        img: social,
        desc: "Back-end application where users can create an account with thoughts, comment on other's thoughts, and add reactions."
    }

]
